import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class LoaderService {
  loaderTimeout = 0;
  activeRequestCount: number = 0;
  loaderSubject = new BehaviorSubject(null);
  constructor() {
  }

  public addRequest(): void {
    this.activeRequestCount = this.activeRequestCount + 1;
    this.loaderSubject.next(this.activeRequestCount);
  }

  public removeRequest(): void {
    this.activeRequestCount = this.activeRequestCount - 1;
    this.loaderSubject.next(this.activeRequestCount);

  }

  public reset(): void {
    this.activeRequestCount = 0;
    this.loaderSubject.next(this.activeRequestCount);
  }

  public getLoading(): boolean {
    return this.activeRequestCount > 0;
  }
}
