import { Routes } from "@angular/router";
import { AuthGuard } from "./shared/services/guards/auth.guard";
import { NotAuthGuard } from "./shared/services/guards/not-auth.guard";

export const appRoutes: Routes = [
  { path: "", redirectTo: "admin", pathMatch: "full" },
  {
    path: "admin",
    loadChildren: () => import("src/app/pages/admin/routes"),
    canActivate: [AuthGuard],
  },
  {
    path: "auth",
    loadChildren: () => import("src/app/pages/auth/routes"),
    canActivate: [NotAuthGuard],
  },
  { path: "**", redirectTo: "auth", pathMatch: "full" },
];
