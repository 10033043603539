import {Injectable} from "@angular/core";
import {ApiManagerService} from "./api-manager.service";
import {Constants} from "../constants";
import {ResponseObject} from "../interfaces";
import {IdentityResponse} from "../../pages/auth/models/user";
import {Observable} from "rxjs";
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class AuthService {
  authToken: string = '';
  refreshToken: string = '';
  constructor(public apiManager: ApiManagerService, private router: Router) {
  }

  public updateAccessToken(): Observable<ResponseObject> {
    // let tok
    // let urlencoded = new URLSearchParams();
    // urlencoded.append("grant_type", "refresh_token");
    // urlencoded.append("client_id", "webbackoffice");
    // urlencoded.append("refresh_token", );
    // urlencoded.append("username", "user");
    return this.apiManager.postRequest(Constants.identityApi + Constants.identity.refreshToken, {token: this.getRefreshToken()}, true);
  }

  public setToken(token: string): void {
    this.authToken = token;
    localStorage.setItem('token', token);
  }

  public setIdentityParams(identityResponse: IdentityResponse): void {
    this.setToken(`${identityResponse.token_type} ${identityResponse.access_token}`);
    this.setRefreshToken(identityResponse.refresh_token);
  }

  public setRefreshToken(refreshToken: string): void {
    this.refreshToken = refreshToken;
    localStorage.setItem('refreshToken', refreshToken);
  }

  getToken(): string | null | undefined {
    return  localStorage.getItem('token');
  }

  getRefreshToken(): string {
    return  localStorage.getItem('refreshToken') || null;
  }

  logOut(): void {
    localStorage.clear();
    this.router.navigate(['auth']);
  }
}
