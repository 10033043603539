import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {MessageModalComponent} from "../components/message-modal/message-modal.component";
import {MessageModalData} from "../interfaces";
import {ResponseMessageStatuses} from "../enums";
import {IndividualConfig, ToastrService} from "ngx-toastr";
import {ToastrInnerComponent} from "../components/toastr-inner/toastr-inner.component";

@Injectable({providedIn: 'root'})
export class MessageService {
  errorShowed = false;
  constructor(private _snackBar: MatSnackBar,
              public dialog: MatDialog, private toastr: ToastrService) {}

  public showMessage(message: string, status?: number) {
    if(!status) {
      status = ResponseMessageStatuses.SUCCESS;
    }
    let modalData: MessageModalData = {
      status: status,
      text: message
    }

    //this.openDialog(modalData);
    // this._snackBar.open(message, undefined, {
    //   duration: 7000
    // });
    this.openToastr(modalData);
  }

  public openToastr(modalData: MessageModalData): void {
    let toastrConfig = {
      positionClass: 'toast-bottom-right',
      progressBar: true,
      toastComponent: ToastrInnerComponent,
      payload: modalData,
      toastClass: 'toastContainer',
    }
    switch (modalData.status) {
      case ResponseMessageStatuses.ERROR:
        this.toastr.error(modalData.text, 'Error', toastrConfig);
        break;
      case ResponseMessageStatuses.SUCCESS:
        this.toastr.success(modalData.text, 'Success', toastrConfig);
        break;
      case ResponseMessageStatuses.INFO:
        this.toastr.info(modalData.text, 'Info', toastrConfig);
        break;
      default:
        this.toastr.info(modalData.text, 'Info', toastrConfig);
    }
  }

  public openDialog(modalData: MessageModalData) {
    if(!this.errorShowed) {
      this.errorShowed = true;
      let dialogRef = this.dialog.open(MessageModalComponent, {
        width: '400px',
        closeOnNavigation: false,
        data: modalData,
      });
      dialogRef.afterClosed().subscribe(res => {
        this.errorShowed = false;
      });
    }
  }


}
