import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ActionModalData} from "../../interfaces";

@Injectable({
    providedIn: 'root'
})
export class WarningModalService {
    warningModalServiceSubject: Subject<any> = new Subject<any>();
    modalData: ActionModalData;
    constructor(){
    }

    public openModal(data?: ActionModalData): Subject<any> {
        // this.modalData = this.configModalData(componentData, additionalData, componentType, component);
        this.warningModalServiceSubject.next({opened: true, data: data});
        return this.warningModalServiceSubject;
    }

    public closeModal(data?: any): void {
        this.warningModalServiceSubject.next({opened: false, data: data || null});
    }

    public getModalData(): ActionModalData {
        return this.modalData;
    }

}
