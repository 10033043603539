import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { GlobalErrorHandler } from './globalErrorHandler';

@Injectable()
export class CompositeErrorHandler implements ErrorHandler {
  constructor(
    private appInsightsErrorService: ApplicationinsightsAngularpluginErrorService,
    private globalErrorHandler: GlobalErrorHandler
  ) {}

  handleError(error: any): void {
    // Delegate error handling to each service
    this.appInsightsErrorService.handleError(error);
    this.globalErrorHandler.handleError(error);
    
    // Optionally, add further logic or rethrow the error
    // console.error('Error handled by CompositeErrorHandler:', error);
  }
}
