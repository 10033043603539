export enum SvgTypes {
  Inventory = 1,
  Down = 2,
  Invoices = 3,
  Purchase_order = 4,
  Bill = 5,
  Expense = 6,
  Income = 7,
  Customers = 8,
  Employees = 9,
  Kitchen = 10,
  Accounting = 11,
  Reports = 12,
  Settings = 13,
  Notification = 14,
  Search = 15
}

export enum ModalComponentTypes {
  ACCOUNT_TRANSACTIONS,
  ACCOUNTS_ADJUST,
  ACCOUNTS,
  BALANCE_TRANSFER,
  BILL_PAY,
  BILL,
  BILLER,
  BRANCH,
  CATEGORY_REPLACE,
  CATEGORY,
  CLOCK_IN_OUT,
  COST,
  COUPON,
  CRV,
  CUSTOMER_GROUP,
  CUSTOMER,
  EMPLOYEE,
  END_OF_SHIFT,
  ESL,
  EXPENSE_PAY,
  EXPENSE,
  IN_STOCK_ADJUSTMENT,
  INCOME_ADJUSTMENT,
  INCOME_SOURCE,
  INCOME,
  INVENTORY_ITEM,
  INVOICE_ACCOUNT_RECEIVABLE,
  INVOICE_PAY,
  INVOICE_RECEIVER,
  KP_MOVE,
  KP_PRODUCTION,
  KP_RECIPE,
  MERGE_ITEMS,
  MIX_AND_MATCH,
  OWNER_DRAW,
  OWNER,
  PAYROLL_DETAILS,
  PO_PAYMENT,
  POS_LOOKUP_SETTINGS,
  PRICE_CHANGE_ALERT,
  PRICE_TAG_TEMPLATES,
  PRINTERS,
  PURCHASE_ORDER_CREATE,
  PURCHASE_ORDER_RECEIVE,
  RESET_PASSWORD,
  RETAIL_PRICE,
  RETURN_TO_VENDOR,
  SALE_PRICE,
  SCHEDULE_EDIT_ASSIGNMENT,
  SCHEDULE_EDIT_SHIFT,
  SCHEDULE_EVENT,
  SCHEDULE_SET_POSITIONS,
  SCHEDULE,
  SCHEDULING_SAVE,
  SHRINK,
  TAX,
  TEMPLATE_SCHEDULE,
  TEMPLATE_SCHEDULING,
  TRANSFERS,
  VENDOR_CATALOG_COMMON,
  VENDOR_CATALOG_RECENT,
  VENDOR_CATALOG,
  VENDOR_PAY,
  VENDOR,
}


export enum GridDetailTypes {
  RETAIL = 1,
  SALE = 2,
  RETURN_TO_VENDOR_DETAILS = 3
}

export enum ProductLookupViewType {
  NAME = 1,
  ITEM_NUMBER = 2
}

export enum SystemCode {
  DRAFT = 'Draft',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  CANCELED = 'Canceled',
  CREATED = 'Created',
  UPDATE_IN_POS = 'UpdatedInPOS',
  UPDATED = 'Updated',
  FIXED = 'Fixed',
  AMOUNT = 'Amount',
  PERCENT = 'Percent',
  NON_OFFER = 'NonOffer',
  DECLINED = 'Declined',
  DELETE ='Delete',
  CREDITED = 'Credited',
  PENDING = 'Pending',
  OPEN = 'Open',
  CASH = 'Cash',
  PAID = 'Paid',
  PartiallyPaid = 'PartiallyPaid',
  CHECK = 'Check',
  CHECKING = 'Checking',
  SAVINGS = 'Savings',
  CC = 'CC',
  ACH = 'ACH',
  CREDITCARD = 'CreditCard',
  BANKACCOUNT = 'BankAccount',
  WEBPAYMENT = 'WebPayment',
  UNCONFIRMED = 'Unconfirmed',
  CONFIRMED = 'Confirmed',
  BRANCH = 'Branch',
  REVIEWED = 'Reviewed',
  IGNORED = 'Ignored',
  APPROVED = 'Approved',
  NO = 'No',
  PreApproval = 'PreApproval',
  YES = 'Yes',
  MIXED = 'Mixed',
  BULK = 'Bulk',
  OWNER = 'Owner',
  BILL = 'Bill',
  BILLS = 'Bills',
  EXPENSE = 'Expenses',
  INVOICES = 'Invoices',
  INCOME = 'Income',
  ACTIVE = 'Active',
  IN_ACTIVE = 'InActive',
  DATE = 'Date',
  DayOfWeek = 'DayOfWeek',
  TIME = 'Time',
  EXPIRED = 'Expired',
  OTHER = 'Other',
  DAMAGED = 'Damaged',
  PARTLY_COMPLETED = 'PartlyCompleted',
  PARTLY_RECEIVED = 'PartiallyReceived',
  RECEIVED = 'Received',
  FUTURE= 'Future',
  CURRENT = 'Current',
  CANCEL = 'Cancel',
  CREDITED_RECEIVED = 'CreditReceived',
  ManagersApproval = 'ManagersApproval',
  RETURNED = 'Returned',
  VendorsApprove = 'VendorsApprove',
  IN_PROGRESS = 'InProgress',
  RETURNED_BACK = 'ReturnedBack',
  Q = 'Q',
  CANCEL_SALE = 'CancelSale',
  EndOfMonth = 'EndOfMonth',
  UponDelivery = 'UponDelivery',
  CreditRecurved = 'CreditRecurved',
  DirectSale = 'DS',
  InternalPurchaseOnly = 'IPO',
  InDirectSale = 'IS',
  LB = 'LB',
  OZ = 'OZ',
  KG = 'KG',
  EACH = 'Each',
  CASE = 'Case',
  WEIGHT = 'Weight',
  NOTFORSALE = 'NFS',
  Discontinued  = 'D',
  STANDARD = 'Standard',
  P18 = 'P18',
  P21 = 'P21',
  SALE = 'Sale',
  RETAIL = 'Retail',
  PENDING_PRICE_TAG = 'PPT',
  PENDING_APPROVAL = 'PA',
  ACTIVE_SALE = 'AS',
  FUTURE_FU = 'FU',
}

export enum TreeTypes {
  BRANCH = 1,
  CATEGORY = 2,
  ORGANIZATIONALSTRUCTURE = 3,
}


/**
 * ISO Weekday numbers
 */
export enum WeekDays {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

/**
 * en-US / Microsoft System.DayOfWeek weekday numbers
 */
export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum UploaderViewType {
  TEXT = 1,
  BUTTON = 2
}

export enum GridStructureNumberType {
  Money = 'money',
  Number = 'number',
  PERCENT = 'percent',
  PHONE = 'phone',
  DATE = 'date',
  Time = 'time'

}

export interface CustomTime {
  value: string;
  value24: string;
}


export enum ResponseMessageStatuses {
  SUCCESS = 1,
  ERROR = 2,
  INFO = 3
}

export enum DeliveredTypes {
  FREIGHT= 1,
  UPS = 2,
  FEDEX = 3,
  USPS = 4,
  PICKUP = 5,
  OTHER = 6
}

export enum DiscrepancyTypes {
  Unknown = 0,
  Counterfit = 1,
  OverCharge = 2,
  UnderCharge = 3,
  CashPickupError = 4,
  VendorPayoutError = 5,
  BreakBillError = 6,
}

export enum DisplayTableColumnType {
  Action,
  Avatar,
  BooleanCount,
  BooleanIcon,
  Count,
  Date,
  Email,
  Expand,
  Link,
  LinkSwitch,
  Money,
  Notes,
  Percent,
  Phone,
  Product,
  StringArray,
  Template,
}

export enum DiscountType {
  Amount = 1,
  Percent = 2,
  Fixed = 3
}

export enum AddressType {
  CorporateOffice,
  AccountsPayable,
  AccountsReceivable,
  DistributionCenter,
  Warehouse
}
