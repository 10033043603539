import {Component, OnInit} from '@angular/core';
import {ActionModalData} from "../../interfaces";
import {MainService} from "../../services/main.service";
import {WarningModalService} from "../../services/helpers/warning-modal.service";
import { TranslateModule } from '@ngx-translate/core';
import { CustomButtonDirective } from '../../directives/custom-button.directive';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-warning-modal',
    templateUrl: './warning-modal.component.html',
    styleUrls: ['./warning-modal.component.scss'],
    standalone: true,
    imports: [NgIf, MatIcon, MatButton, CustomButtonDirective, TranslateModule]
})
export class WarningModalComponent implements OnInit{
  isOpen: boolean = false;
  animationName = '';
  actionModalData: ActionModalData;
  constructor(public warningModalService: WarningModalService, public mainService: MainService) {
  }

  ngOnInit() {
    let sub = this.warningModalService.warningModalServiceSubject.subscribe((res: {opened: boolean, data: ActionModalData}) => {
      if(res) {
        if(res.opened) {
          this.isOpen = true;
          this.actionModalData = res.data;
          if(!this.actionModalData) {
            this.actionModalData = {title: 'areYouSure', text: 'areYouSureText'};
          }
          this.setOpenAnimation();
          this.mainService.stopBodyScrolling();
        }
      }
    });
  }

  public closeModal(data?: any): void {
    this.setCloseAnimation();
    setTimeout(() => {
      this.warningModalService.closeModal(data);
      this.mainService.enableBodyScrolling();
      this.isOpen = false;
    }, 210);
  }

  public setOpenAnimation(): void {
    this.animationName = 'slide-in-top';
  }

  public setCloseAnimation(): void {
    this.animationName = 'slide-out-bottom';
  }
}
