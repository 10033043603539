import {Component, OnInit} from '@angular/core';
import {ToastPackage, ToastrService} from "ngx-toastr";
import {MessageModalData} from "../../interfaces";
import {ResponseMessageStatuses} from "../../enums";
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-toastr-inner',
    templateUrl: './toastr-inner.component.html',
    styleUrls: ['./toastr-inner.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, MatIcon, TranslateModule]
})
export class ToastrInnerComponent implements OnInit{
  payload: MessageModalData = null;
  package = null;
  responseMessageStatuses = ResponseMessageStatuses;
    constructor(public toastPackage: ToastPackage, private toastrService: ToastrService) {
    }

    ngOnInit() {
      this.package = this.toastPackage;
      this.payload = this.toastPackage.config.payload;
      setTimeout(() => {
        this.toastrService.remove(this.package.toastId);
      }, 5000);
    }

    public close(): void {
      this.toastrService.remove(this.package.toastId);
    }
}
