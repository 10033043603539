import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { UserDataService } from "./user-data.service";

@Injectable({
  providedIn: "root",
})
export class UnisightTitleStrategyService extends TitleStrategy {
  branchName: string = "";

  constructor(
    private readonly title: Title,
    private userDataService: UserDataService,
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    const authArea: boolean = routerState.url.includes("auth");
    if (title !== undefined) {
      if (!this.branchName && !authArea) {
        this.userDataService?.getBranchName().subscribe((branchName) => {
          this.branchName = branchName;
          this.setTitleWithFormat(title);
        });
      } else {
        this.setTitleWithFormat(title, !authArea);
      }
    } else {
      this.title.setTitle(`UniSight`);
    }
  }

  private setTitleWithFormat(title: string, showBranchName: boolean = true) {
    this.title.setTitle(
      showBranchName
        ? `${title} | UniSight - ${this.branchName}`
        : `${title} | UniSight`,
    );
  }
}
