import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MessageModalData} from "../../interfaces";
import {ResponseMessageStatuses} from "../../enums";
import {AppComponent} from "../../../app.component";
import { TranslateModule } from '@ngx-translate/core';
import { CustomButtonDirective } from '../../directives/custom-button.directive';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss'],
    standalone: true,
    imports: [MatIcon, NgIf, MatButton, CustomButtonDirective, TranslateModule]
})
export class MessageModalComponent implements OnInit{
  responseMessageStatuses = ResponseMessageStatuses;
  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageModalData, public dialogRef: MatDialogRef<AppComponent>) {
  }

  ngOnInit() {
  }

  public close(): void {
    this.dialogRef.close();
  }
}
