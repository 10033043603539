import { Environment } from "./interfaces/environment.interface";

export const environment: Environment = {
  production: false,
  environment: "dev",
  appInsightsConnectionString:
    "InstrumentationKey=8ff2ceae-f477-4c70-88d7-880503751394;IngestionEndpoint=https://westus3-1.in.applicationinsights.azure.com/;ApplicationId=c6a4632d-77c4-4f28-916d-616f709676dd",
  inventoryApi: "https://app-inventory-api-dev-001.azurewebsites.net/api/",
  employeeApi: "https://app-employee-api-dev-001.azurewebsites.net/api/",
  identityApi: "https://app-identity-api-dev-001.azurewebsites.net/",
  customerApi: "https://app-customer-api-dev-001.azurewebsites.net/api/",
  expensesApi: "https://app-expenses-api-dev-001.azurewebsites.net/api/",
  accountingApi: "https://app-accounting-api-dev-001.azurewebsites.net/api/",
  kitchenProductionApi:
    "https://app-kitchenproduction-api-dev-001.azurewebsites.net/api/",
  marketingApi: "https://app-marketing-api-dev-001.azurewebsites.net/api/",
  posApi: "https://app-pos-api-dev-001.azurewebsites.net/api/",
  serverlessApi: "https://apim-service-unisight-dev.azure-api.net",
  widgetApi: {
    baseURL: "https://apim-service-unisight-dev.azure-api.net",
    cash: {
      history: {
        endpoint: "/cash/history",
        version: "v1",
      },
      dollarsPerTicket: {
        endpoint: "/transactions/dollars-per-ticket",
        version: "v1",
      }
    },
    cashiers: {
      status: {
        endpoint: "/cashiers/status",
        version: "v1",
      },
      discounts: {
        endpoint: "/cashiers/discounts",
        version: "v1"
      }
    },
    departments: {
      top: {
        endpoint: "/departments/top",
        version: "v1",
      },
      list: {
        endpoint: "/departments/list",
        version: "v1",
      },
    },
    products: {
      top: {
        endpoint: "/products/top",
        version: "v1",
      },
    },
    teams: {
      status: {
        endpoint: "/teams/status",
        version: "v1",
      },
    },
    transactions: {
      submit: {
        endpoint: "/transactions",
        version: "v1",
      },
      history: {
        endpoint: "/transactions/history",
        version: "v1",
      },
      grossProfit: {
        endpoint: "/transactions/gross-profit",
        version: "v1",
      },
      quickStats: {
        endpoint: "/transactions/quick-grid",
        version: "v1",
      },
    },
    vendor: {
      payouts: {
        endpoint: "/cash/vendor-payouts",
        version: "v1"
      }
    }
  },
  deviceRegApi: {
    baseURL: "https://apim-service-unisight-dev.azure-api.net",
    deviceStatus: {
      endpoint: "/device-status",
      version: "v1",
    },
  },
};
