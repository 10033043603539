<ng-container *ngIf="modalData">
  <div class="modalContainerMain {{animationName}}">
    <div class="modalContainer" [ngStyle]="{'width': modalData?.width}"  [class]="modalData?.displayClass">
      <div class="modalContainerInner">
        <div class="modalContainerHeader">
          <h2 class="pageTitle">{{modalData.title}}</h2>
          <div class="modalContainerHeaderClose" (click)="closeModal(null)">
            <mat-icon>close</mat-icon>
          </div>
        </div>
        <div class="modalContainerBody">
          <div #modalBodyContainer></div>
        </div>
      </div>
    </div>
  </div>
  <div class="modalContainerBackground" [@backgroundFade]="animationState"></div><!--(click)="closeModal(null)"-->
</ng-container>
