<div class="messageModalContainer">
  <div class="messageModalContainerHeaderIcon">
    <mat-icon class="clickable"  (click)="close()">close</mat-icon>
  </div>
  <div class="messageModalContainerHeader">
    <h3 class="pageTitle" *ngIf="data.status == responseMessageStatuses.SUCCESS">{{'success' | translate}}</h3>
    <h3 class="pageTitle warnColor" *ngIf="data.status == responseMessageStatuses.ERROR">{{'error' | translate}}</h3>
  </div>
  <div class="messageModalContainerBody">
    <p *ngIf="data.text" class="body-2" [innerText]="data?.text"></p>
  </div>
  <div class="messageModalContainerFooter">
    <button mat-flat-button customButton class="mainButton" (click)="close()" color="primary">{{'ok' | translate}}</button>
  </div>
</div>
