import { catchError, firstValueFrom, map, Observable, of, switchMap, tap } from "rxjs";

import { Injectable } from "@angular/core";

import { Constants } from "../constants";
import { ResponseObject } from "../interfaces";
import { ApiManagerService } from "./api-manager.service";

@Injectable({
  providedIn: "root",
})
/**
 * Service responsible for handling user profile–related operations.
 */
export class UserDataService {
  constructor(
    private apiManager: ApiManagerService,
  ) {}

  // Memoized branchId
  private branchId: number = null;
  private branchName: string = "";
  private permissions: string[] = [];
  private universalEditor: boolean | null = null;

  public async hasPermission(permission: string, memoize: boolean = true): Promise<boolean> {
    try {
        if (memoize && this.permissions.length > 0) {
            return this.permissions.includes(permission);
        } else {
            const response = await this.getProfile(false).toPromise();
            this.permissions = response.success?.permissions || [];
            return this.permissions.includes(permission);
        }
    } catch (error) {
        console.error("Permission Response Error:", error);
        return false; // Return false on error
    }
  }

  /**
   * Gets profile data for the current user.
   * @param hideLoading Whether to hide data load from global loading indicator.
   * @returns user profile object
   */
  public getProfile(hideLoading: boolean = true): Observable<ResponseObject> {
    return this.apiManager
      .getRequest(
        Constants.employeeApi + Constants.employee.userGetProfile,
        null,
        hideLoading,
      )
      .pipe(
        catchError((error) => {
          return of({ success: null, error: error, message: null });
        }),
      );
  }

  /**
   * Retrieves the logged-in account's branch ID.
   * @param memoize - Flag indicating whether to memoize the branch ID.
   * @returns An observable that emits the branch ID.
   */
  public getBranchId(memoize: boolean = true): Observable<number> {
    return of(null).pipe(
      switchMap(() => {
        if (memoize && this.branchId) {
          return of(this.branchId);
        } else {
          return this.getProfile(false).pipe(
            map((response) => {
              return response.success?.branchId;
            }),
            tap((branchId) => {
              this.branchId = memoize && branchId ? branchId : this.branchId;
            }),
            catchError((error) => {
              console.error("Branch ID Response Error: ", error);
              return of(null);
            }),
          );
        }
      }),
    );
  }

  /**
  * Retrieves the logged-in account's branch name.
  * @param memoize - Flag indicating whether to memoize the branch name.
  * @returns An observable that emits the branch name.
  */
  public getBranchName(memoize: boolean = true): Observable<string> {
    return of(null).pipe(
      switchMap(() => {
        if (memoize && this.branchName) {
          return of(this.branchName);
        } else {
          return this.getProfile(false).pipe(
            map((response) => {
              return response.success?.branch;
            }),
            tap((branchName) => {
              this.branchName = memoize && branchName ? branchName : this.branchName;
            }),
            catchError((error) => {
              console.error("Branch Name Response Error: ", error);
              return of(null);
            }),
          );
        }
      }),
    );
  }

  public async getBranchIsUniversal(memoize: boolean = true): Promise<boolean> {
    if (memoize && this.universalEditor !== null) {
      return this.universalEditor;
    } else {
      try {
        const universalEditor = await firstValueFrom(
          this.getProfile(false).pipe(
            map((response) => response.success?.universalEditor || false),
            tap((editor) => {
              this.universalEditor = memoize && editor ? editor : this.universalEditor;
            }),
            catchError((error) => {
              console.error('Branch Universal Editor Response Error: ', error);
              return of(false); // Return a default value in case of error
            })
          )
        );
        return universalEditor;
      } catch (error) {
        console.error('Error fetching Branch Universal Editor:', error);
        return false; // Default return value in case of unexpected errors
      }
    }
  }

  public clearCache(): void {
    this.branchId = null;
    this.branchName = "";
    this.permissions = [];
    this.universalEditor = null;
  }
}
