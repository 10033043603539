<div class="toastMainContainer" [ngClass]="{'success': payload.status == responseMessageStatuses.SUCCESS,
 'error': payload.status == responseMessageStatuses.ERROR,
  'info': payload.status == responseMessageStatuses.INFO}">
  <div class="toastMainContainerInner">
    <div class="leftBlock">
      <div class="icon">
        <mat-icon *ngIf="payload?.status == responseMessageStatuses.SUCCESS">task_alt_black</mat-icon>
        <mat-icon *ngIf="payload?.status == responseMessageStatuses.INFO">error_outline</mat-icon>
        <mat-icon *ngIf="payload?.status == responseMessageStatuses.ERROR">cancel_black</mat-icon>
      </div>
      <div class="message">
        <div class="messageTitle">
          <ng-container *ngIf="payload?.status == responseMessageStatuses.INFO">{{'info' | translate}}</ng-container>
          <ng-container *ngIf="payload?.status == responseMessageStatuses.SUCCESS">{{'success' |
            translate}}</ng-container>
          <ng-container *ngIf="payload?.status == responseMessageStatuses.ERROR">{{'error' | translate}}</ng-container>
        </div>
        <div class="messageDescription" [innerHTML]="payload?.text"></div>
      </div>
    </div>
    <div class="close">
      <mat-icon (click)="close()">close</mat-icon>
    </div>
  </div>
</div>