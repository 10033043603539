import {Injectable} from "@angular/core";
import { Router } from "@angular/router";
import {AuthService} from "../auth.service";

@Injectable({providedIn: 'root'})
export class NotAuthGuard {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate() {
    if(this.authService.getToken()) {
      this.router.navigate(['admin']);
      return false;
    }else {
      return true;
    }
  }
}
