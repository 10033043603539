import {Component, ViewEncapsulation} from '@angular/core';
import {LoaderService} from "../../services/loader.service";
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatProgressBar]
})
export class LoaderComponent {
  constructor(public loaderService: LoaderService) {
  }


}
