import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { LoaderService } from "../services/loader.service";
import { Subscription, fromEvent, throttleTime } from 'rxjs';

@Directive({
    selector: '[customButton]',
    standalone: true
})
export class CustomButtonDirective {
    constructor(private eleRef: ElementRef, public loaderService: LoaderService) {
        // let sub = this.loaderService.loaderSubject.subscribe(l => {
    //   if (l != 0) {
    //     eleRef.nativeElement.setAttribute('disabled', 'true');
    //   } else {
    //     eleRef.nativeElement.removeAttribute('disabled')
    //   }
        // });
    }
}
