import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class MainService {
  constructor() {
  }


  public stopBodyScrolling(): void {
    try {
      let body = document.body;
      body.classList.add('stop-scrolling');
      // document.body.addEventListener('touchmove', this.preventDefault, { passive: false });

    }catch (e) {
      //
    }
  }

  public enableBodyScrolling(): void {
    try {
      let body = document.body;
      body.classList.remove('stop-scrolling');
      // document.body.removeEventListener('touchmove', this.preventDefault);
    }catch (e) {
      //
    }
  }
}
