import { provideEcharts } from "ngx-echarts";
import { provideEnvironmentNgxMask, provideNgxMask } from "ngx-mask";
import { ToastrModule } from "ngx-toastr";

import {
  FullscreenOverlayContainer,
  OverlayContainer
} from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from "@angular/common/http";
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom
} from "@angular/core";
import {
  MAT_DATE_LOCALE,
  provideNativeDateAdapter
} from "@angular/material/core";
import { MAT_MENU_DEFAULT_OPTIONS } from "@angular/material/menu";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import {
  provideRouter,
  TitleStrategy,
  withComponentInputBinding
} from "@angular/router";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule
} from "@danielmoncada/angular-datetime-picker";
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { environment } from "../environments/environment";
import { appRoutes } from "./app.routes";
import { Constants } from "./shared/constants";
import { InterceptorService } from "./shared/services/interceptor.service";
import { UnisightTitleStrategyService } from "./shared/services/unisight-title-strategy.service";
import { GlobalErrorHandler } from "@shared/globalErrorHandler";
import { CompositeErrorHandler } from "@shared/compositeErrorHandler";

export { environment };

function loadRemoteEnv() {
  return () => {
    return new Promise<unknown>((resolve) => {
      Constants.environment = environment.environment;
      Constants.inventoryApi = environment.inventoryApi;
      Constants.employeeApi = environment.employeeApi;
      Constants.identityApi = environment.identityApi;
      Constants.customerApi = environment.customerApi;
      Constants.accountingApi = environment.accountingApi;
      Constants.expensesApi = environment.expensesApi;
      Constants.kitchenProductionApi = environment.kitchenProductionApi;
      Constants.marketingApi = environment.marketingApi;
      Constants.posApi = environment.posApi;

      // TODO(jg): shallow merging of objects probably isn't really sufficient
      // here, but it shouldn't be a problem for now
      Constants.widgetApi = {
        ...Constants.widgetApi,
        ...environment.widgetApi,
      };

      setTimeout(() => {
        resolve(true);
      }, 50);
    });
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      CommonModule,
      OwlDateTimeModule,
      OwlNativeDateTimeModule,
      ToastrModule.forRoot(),
      TranslateModule.forRoot({
        defaultLanguage: "en",
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    provideAnimationsAsync(),
    provideEcharts(),
    provideEnvironmentNgxMask(),
    provideHttpClient(withInterceptorsFromDi()),
    provideNativeDateAdapter(),
    provideNgxMask(),
    provideRouter(appRoutes, withComponentInputBinding()),
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: "mat-menu-global" },
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-US" },
    { provide: APP_INITIALIZER, useFactory: loadRemoteEnv, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    {
      provide: TitleStrategy,
      useClass: UnisightTitleStrategyService,
    },
    {
      provide: ErrorHandler,
      useClass: CompositeErrorHandler,
    },
    ApplicationinsightsAngularpluginErrorService,
    GlobalErrorHandler
  ],
};
