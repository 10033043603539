import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ActionModalData, ModalData} from "../../interfaces";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ActionModalService {
  actionModalServiceSubject: Subject<any> = new Subject<any>();
  modalData: ActionModalData;
  constructor(private translate: TranslateService){
  }

  public openModal(data?: ActionModalData): Subject<any> {
    // this.modalData = this.configModalData(componentData, additionalData, componentType, component);
    this.actionModalServiceSubject.next({opened: true, data: data});
    return this.actionModalServiceSubject;
  }

  public closeModal(data?: any): void {
    this.actionModalServiceSubject.next({opened: false, data: data || null});
  }

  public getModalData(): ActionModalData {
    return this.modalData;
  }

}
