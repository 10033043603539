import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {HttpParams} from "@angular/common/http";
import {catchError, map, Observable} from "rxjs";
import {ResponseObject} from "../interfaces";
import {LoaderService} from "./loader.service";

@Injectable({providedIn: 'root'})
export class ApiManagerService {
  constructor(private apiService: ApiService, private loaderService: LoaderService) {
  }

  public getRequest<T = ResponseObject>(url: string, params?: HttpParams , hideLoading?: boolean, responseType: string = null,): Observable<T> {
    if(!hideLoading){
      this.loaderService.addRequest();
    }
    return this.apiService.get<T>(url, params, responseType).pipe(map((response: T) => {
      if(!hideLoading){
        this.loaderService.removeRequest();
      }
      return response;
    }), catchError(err => {
      if(!hideLoading){
        this.loaderService.removeRequest();
      }
      throw err;
    }))
  }

  public getRequestAny<T = any>(url: string, params?: HttpParams , hideLoading?: boolean, responseType: string = null,): Observable<T> {
    if(!hideLoading){
      this.loaderService.addRequest();
    }
    return this.apiService.get<T>(url, params, responseType).pipe(map((response: T) => {
      if(!hideLoading){
        this.loaderService.removeRequest();
      }
      return response;
    }), catchError(err => {
      if(!hideLoading){
        this.loaderService.removeRequest();
      }
      throw err;
    }))
  }

  public postRequest<T = ResponseObject>(url: string, data: unknown, hideLoading?: boolean, responseType: string = null): Observable<T> {
    if(!hideLoading){
      this.loaderService.addRequest();
    }
    return this.apiService.post<T>(url, data, responseType).pipe(map((response: T) => {
      if(!hideLoading){
        this.loaderService.removeRequest();
      }
      return response;
    }), catchError(err => {
      if(!hideLoading){
        this.loaderService.removeRequest();
      }
      throw err;
    }))
  }

  public patchRequest<T = ResponseObject>(url: string, data: unknown, hideLoading?: boolean, responseType: string = null): Observable<T> {
    if(!hideLoading){
      this.loaderService.addRequest();
    }
    return this.apiService.patch<T>(url, data, responseType).pipe(map((response: T) => {
      if(!hideLoading){
        this.loaderService.removeRequest();
      }
      return response;
    }), catchError(err => {
      if(!hideLoading){
        this.loaderService.removeRequest();
      }
      throw err;
    }))
  }

  public deleteRequest(url: string, data: any, hideLoading?: boolean): Observable<any> {
    if(!hideLoading){
      this.loaderService.addRequest();
    }
    return this.apiService.delete(url, data).pipe(map((response: ResponseObject) => {
      if(!hideLoading){
        this.loaderService.removeRequest();
      }
      return response;
    }), catchError(err => {
      if(!hideLoading){
        this.loaderService.removeRequest();
      }
      throw err;
    }))
  }
}
